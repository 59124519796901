<template>
  <div class="hamburger-wrap" @click="toggleClick" v-if="false">
    <svg-icon
      icon-class="collapse"
      :class="[!isActive && 'is-active', 'hamburger']"
    ></svg-icon>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleClick() {
      this.$emit("toggleClick");
    }
  }
};
</script>
<style lang='scss' scoped>
.hamburger-wrap {
  padding: 0 15px;
  .hamburger {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }

  .hamburger.is-active {
    transform: rotate(180deg);
  }
}
</style>