<template>
  <div class="nav">
<!--    <hamburger-->
<!--      class="hamburger-container"-->
<!--      :is-active="sidebar.opened"-->
<!--    />-->
    <!-- 面包屑 -->
<!--    <breadcrumb class="breadcrumb-container"  style="margin-left: 30px;font-style: italic;"/>-->
	  
	  <div class="head-left">
		  <div class="logo">
			  <img :src="logo"/>
		  </div>
		  <p class="projectName">{{projectName}}</p>
	  </div>
    <div class="right-menu">
	    <div class="server" v-show="userType==='user'">
		    <div><span @click="gotoWallet()">金币余额: {{ balance }}</span><span class="charge" @click="gotoCharge">充值</span></div>
	    </div>
      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click"
      >
        <div class="avatar-wrapper">
	        <div>{{ showUser }}</div>
          <i class="el-icon-caret-bottom" />
          <el-dropdown-menu slot="dropdown">
	          <el-dropdown-item>
		          <span style="display:block;" @click="refresh">刷新数据</span>
	          </el-dropdown-item>
	          <el-dropdown-item>
		          <span style="display:block;">官方客服:<br> {{ contact }}</span>
	          </el-dropdown-item>
            <el-dropdown-item>
              <span style="display:block;" @click="logout">退出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </div>
      </el-dropdown>
	    <div class="server" @click="addSuggest" v-show="userType==='user'">
		    <div class="svg">
			    <svg style="width: 1rem; height:1rem" version="1.1" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" height="1024" width="1024" viewBox="0 0 1024 1024"><path d="M981.333333 561.856a74.666667 74.666667 0 0 1 42.666667 67.477333v64a74.666667 74.666667 0 0 1-42.666667 67.477334V949.333333a32 32 0 0 1-32 32H661.333333v21.333334a21.333333 21.333333 0 0 1-21.333333 21.333333H384a21.333333 21.333333 0 0 1-21.333333-21.333333v-106.666667a21.333333 21.333333 0 0 1 21.333333-21.333333h256a21.333333 21.333333 0 0 1 21.333333 21.333333v21.333333h256v-156.522666a74.666667 74.666667 0 0 1-42.666666-67.477334v-64a74.666667 74.666667 0 0 1 42.666666-67.477333V469.333333c0-223.850667-181.482667-405.333333-405.333333-405.333333S106.666667 245.482667 106.666667 469.333333v113.856A74.666667 74.666667 0 0 1 149.333333 650.666667v64a74.666667 74.666667 0 0 1-149.333333 0v-64a74.666667 74.666667 0 0 1 42.666667-67.477334V469.333333C42.666667 210.133333 252.8 0 512 0s469.333333 210.133333 469.333333 469.333333v92.522667z"></path></svg>
		    </div>
		    <div class="words">反馈</div>
	    </div>
	    
	    <div class="server" @click="goToDocument()">
		    <div class="svg">
			    <svg style="width: 1rem; height:1rem" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
				    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"></path>
			    </svg>
		    </div>
		    <div class="words">指南</div>
	    </div>
    </div>
  </div>
</template>
  

<script>
import { mapState, mapActions } from "vuex";
import Hamburger from "@/components/Hamburger";
import Breadcrumb from "@/components/Breadcrumb";
import {getCookie} from "@/utils/auth";
export default {
  components: {
    Hamburger,
    Breadcrumb
  },

    data() {
        return {
            showUser:'',
	        logo:'',
	        projectName:'',
	        contact:'',
	        balance:0,
	        documentUrl: "",
	        userType:''
        }
    },
  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar,
      userName: state => state.user.username
    })
  },
    mounted() {
	    this.getUserInfo()
	    this.userType = getCookie('userType')
	    this.$http.post('/client/common/getPublicConfig').then(res => {
		    if (res.code === 0) {
			    res.data.list.forEach(item => {
				    if (item.key === 'logo') {
					    this.logo = process.env.VUE_APP_BASE_API + item.value
				    }
				    if (item.key === 'projectName') {
					    this.projectName = item.value
				    }
						if (item.key === 'contact') {
							this.contact = item.value
						}
						if (item.key === 'documentUrl') {
							this.documentUrl = item.value
						}
			    })
		    }
	    })
    },
    methods: {
        ...mapActions("app", ["toggleSideBar"]),
        ...mapActions("user", ["resetToken"]),
	    gotoCharge() {
		    this.$router.push('/wallet/charge')
	    },
	    refresh() {
				window.location.reload()
	    },
        logout() {
          this.resetToken();
            this.$http.post('/client/user/loginOut', {}).then(res => {
                if (res.code === 0) {
                    this.$message({
                        message: "成功",
                        type: "success",
                    });
                    this.$cookies.remove('Authorization')
		                if (getCookie('userType') === 'admin') {
			                this.$router.push('/adminLogin')
		                } else if (getCookie('userType') === 'superAdmin') {
			                this.$router.push('/saasLogin')
		                } else {
			                this.$router.push('/login')
	                  }
                }
            })
        },
	    getUserInfo() {
					let map = {
						'admin': '/admin/user/info',
						'user': '/client/user/info',
						'superAdmin': '/saas/saas/info'
					}
					let path = map[getCookie('userType')]
					this.$http.post(path, {}).then(res => {
						this.showUser = res.data.userInfo.name
						this.balance = res.data.userInfo.balance ? parseFloat(res.data.userInfo.balance.coin).toFixed(0) : 0
					})
	    },
	    addSuggest() {
		    try {
			    this.$router.push('/addSuggest/addSuggest')
		    } catch (e) {
			    console.log(e)
		    }
	    },
	    goToDocument() {
					window.open(this.documentUrl)
	    },
	    gotoWallet() {
		    if (this.$route.path !== '/wallet/record' && this.$router.resolve('/wallet/record')['route']['matched'].length > 0) {
			    this.$router.push('/wallet/record')
		    }
	    }
  }
};
</script>
<style lang='scss' scoped>
.nav {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
	border-bottom: solid 1px #ebeef5;
    display: flex;
    justify-content: flex-end;
  .hamburger-container {
    line-height: 46px;
    height: 100%;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .right-menu {
    height: 100%;
    line-height: 50px;
	  display: flex;
	  align-items: center;
    .right-menu-item {
      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }
  }
}
.avatar-wrapper {
	height: 100%;
	display: flex;
	user-select: none;
	align-items: center;
}
.el-dropdown-menu__item {
	margin-top: 10px;
}
.head-left {
	margin-left: 0.5rem;
	display: flex;
	align-items: center;
	position: fixed;
	top:5px;
	z-index: 100;
	left:0;
	.logo {
		img {
			width: 2rem;
		}
	}
	.projectName {
		font-size: 1.1rem;
		font-weight: 900;
		user-select: none;
		margin-left: 0.3rem;
	}
}
.server {
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: 0 1rem 0 0;
	cursor: pointer;
	user-select: none;
	.svg {
		display: flex;
		align-items: flex-end;
		height: 50%;
		svg {
			height: 100%;
			width: 100%;
		}
	}
	.words {
		display: flex;
		align-items: center;
		height: 50%;
	}
}
.avatar-container {
	height: 100%;
	margin-right: 1rem;
}
.charge {
	color:blue;
	margin-left:0.3rem
}
</style>