<template>
  <div class="sidebar">
      <div v-if="0" class="menuControl" @click="toggleClick">
          <svg-icon
              icon-class="collapse"
              :class="[showSideBar && 'is-active', 'hamburger']"
          ></svg-icon>
      </div>
    <el-scrollbar class="scrollbar-wrapper" wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        @select="select"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in permission_routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
	  
	  <div class="auto-release">
		  <div class="switch">
			  <span>自动挂机<span v-show="autoRelease">{{waitReleaseCount}}</span></span>
			  
			  <el-switch
				  v-model="autoRelease"
				  active-color="#13ce66"
				  inactive-color="#ff4949"
				  @change="switchAutoRelease"
			  />
		  </div>
		  
	  </div>
	  
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem";
import {isPcClient} from "@/utils/utils";

export default {
  components: { SidebarItem },
    props: {
        // 手否有表头栏
        showSideBar: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
	        autoRelease: false,
	        waitReleaseCount: 0,
        };
    },
  computed: {
    ...mapGetters(["permission_routes", "sidebar"]),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      //  默认激活项
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    variables() {
      return {
        menuText: "#171717",
        menuActiveText: "#0065ff",
        menuBg: "#ffffff"
      };
    },
    isCollapse() {
      return !this.sidebar.opened;
    }
  },
	
	watch: {},
  methods: {
	  
	  switchAutoRelease() {
		  if (!isPcClient()) {
			  this.$message.error('自动发布功能启动失败, 请在PC客户端启动自动发布功能')
			  return
		  }
		  pywebview.api.switchAutoRelease(this.autoRelease)
		  if (this.autoRelease) {
				this.$http.post('/client/gen/getArticleList', {'pageNum': 1, 'pageSize': 1, 'status': 'new'}).then(res => {
					this.waitReleaseCount = res.data['total']
				})
		  }
	  },
	  
    select() {
      this.$emit('select')
    },
      toggleClick() {
          this.$parent.toggleClick()
      }
  }
};
</script>
<style lang='scss'>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.sidebar-container {
    width: 150px;
		border-right: solid 1px #ebeef5;
    .menuControl {
        position: absolute;
        top:3.3rem;
        left: 100%;
        font-size: 20px;
        padding: 0 10px 10px 0;
        cursor: pointer;
    }
}

.hamburger.is-active {
    transform: rotate(180deg);
}
.el-submenu .el-menu-item {
    height: 40px !important;
    line-height: 40px !important;
}

.sidebar {
	position: relative;
	.auto-release {
		position: absolute;
		bottom: 3.5rem;
		width: 100%;
		display: flex;
		justify-content: center;
		border-top: 1px solid #ccc;
		padding-top: .5rem;
		.switch {
			white-space: nowrap;
			overflow:hidden;
			display: flex;
		}
		span {
			margin-left:0.3rem;
			font-size: .84rem;
			font-weight: 900;
		}
	}
}
</style>
<style lang="scss" scoped>
::v-deep .el-menu-item{
	font-size: 16px;
}
</style>