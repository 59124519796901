<template>
  <div class="app-wrapper" :class="classObj">
      <!-- 左侧导航栏 -->
    <Sidebar ref="sideBar" class="sidebar-container" :class="{showSideBar:showSideBar}" :hide="showSideBar"/>
    <div ref="mainContainer" class="main-container" >
      <!-- 导航栏 -->
      <nav-bar />
      <!-- tags -->
      <tags-view />

      <app-main :style="{marginLeft: !showSideBar ? mainMargin : 0, maxWidth:maxWidth}"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Sidebar from "@/components/Sidebar";
import NavBar from "@/components/NavBar";
import TagsView from "@/components/TagsView";
import AppMain from "./AppMain";

export default {
  data() {
    return {
        showSideBar: false,
        mainMargin:'0',
        maxWidth: '100%'
    };
  },

  components: {
    Sidebar,
    NavBar,
    AppMain,
    TagsView
  },

  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation
      };
    },
  },

  mounted() {
    this.toggleClick()
	  this.toggleClick()
	  window.addEventListener('resize', this.handleResize);
  },

  methods: {
    ...mapActions("app", ["toggleSideBar"]),
	  handleResize() {
		  this.toggleClick()
		  this.toggleClick()
	  },
      toggleClick() {
        if (this.showSideBar) {
            //即将展开
            this.maxWidth = (this.$parent.$el.offsetWidth - this.mainMargin.split('px')[0]-20)+'px'
        } else {
            //即将关闭
            this.mainMargin = this.$refs.sideBar.$el.offsetWidth+'px'
            this.maxWidth = '100%'
        }
        this.showSideBar = !this.showSideBar
	      this.$forceUpdate()
      }
  }
};
</script>
<style lang='scss' scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";
.app-wrapper {
  @include clearfix;
  position: relative;
    display: flex;
    justify-content: center;
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}

.hamburger-wrap{
  display: none;
}
.showSideBar {
    width: 0 !important;
    right: 100%;
}

::v-deep .el-form-item__label {
    padding-right: 2px;
}
</style>